.button {
  position: relative;
  background-color: transparent;
  border: 2px solid #d1d1d1;
  border-radius: 15px;
  height: 50px;
  transition: 0.2s ease-in-out;
}

.button:hover {
  background-color: #d1d1d1;
  color: #1a1a11;
}

.button--flex {
  display: inline-flex;
  align-items: center;
}