.about {
  background-color: #181818;
}

.section__title {
  font-size: 60px;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.about__container {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 4rem;
}

.about__img {
  width: 350px;
  border-radius: 1.5rem;
  justify-self: center;
}

.about__info {
  grid-template-columns: repeat(3, 140px);
  gap: 0.5rem;
  margin-bottom: var(--mb-2);
}

.about__box {
  background-color: #151515;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
}

.about__icon {
  font-size: 1.5rem;
  margin-bottom: var(--mb-0-5);
}

.about__title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.about__subtitle {
  font-size: var(--tiny-font-size);
}

.about__description {
  padding: 0 4rem 0 0;
  margin-bottom: var(--mb-2-5);
}

.about__span {
  grid-template-columns: repeat(2, 210px);
  column-gap: 150px;
  color: var(--green);
  font-size: 15px;
  margin-bottom: var(--mb-2-5);
}

.span__answer {
  margin-left: 10px;
  color: #d1d1d188;
  font-size: 14px;
}

.uil-file-download {
  margin-left: 5px;
}

/* BREAKPOINTS */
/* large devices */
@media screen and (max-width: 992px) {
  .about__container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem
  }

  .about__img {
    width: 220px;
  }

  .about__box {
    padding: 0.75rem 0.5rem;
  }

  .about__data {
    text-align: center;
  }

  .about__info {
    justify-content: center;
  }

  .about__description {
    padding: 0 5rem;
    margin-bottom: 2rem;
  }
}



/* medium devices */
@media screen and (max-width: 960px) {
  .about__span {
    grid-template-columns: repeat(2, 320px);
    column-gap: 60px;
  }
}

@media screen and (max-width: 597px) {
  .about__span {
    grid-template-columns: repeat(2, 200px);
    column-gap: 60px;
  }
}

@media screen and (max-width: 576px) {
  .about__info {
    grid-template-columns: repeat(3, 1fr);
  }

  .about__description {
    padding: 0;
  }

  .about__span {
    grid-template-columns: repeat(2, 140px);
    column-gap: 60px;
  }

  .span__answer {
    margin-left: 10px;
    color: #d1d1d188;
  }
}


/* small devices */
@media screen and (max-width: 350px) {
  .about__info {
    grid-template-columns: repeat(2, 1fr);
  }
}