.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.section__subtitle {
  color: #d1d1d184;
  font-size: 14px;
}

.work_span {
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
  color: var(--green);
}

.work_span a {
  text-decoration: underline;
  cursor: pointer;
  color: var(--green);
  transition: 0.1s ease-in-out;
}

.work_span a:hover {
  color: #36c769b4;
}

.work__item {
  cursor: pointer;
  color: #d1d1d1;
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
  transition: 0.2s ease-in-out;
}

.work__item:hover {
  background-color: #34ff7b3d;
  color: var(--green);
}

.work {
  background-color: #181818;
}

.work__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.work__card {
  background-color: #151515;
  border: 2px solid #5151514c;
  padding: 1.25rem;
  border-radius: 1rem;
}

.work__card:hover {
  transition: 0.2s ease-in-out;
  border: 2px solid var(--green);
}

.work__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
  transition: 0.2s ease-in-out;
}

.work__img:hover {
  transform: scale(1.04) rotate(-2deg);
  filter: hue-rotate(7deg) contrast(101%) saturate(200%);
  cursor: pointer;
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
  color: #d1d1d1;
}

.work__techs {
  font-weight: var(--font-normal);
  margin-bottom: 3px;
}

.work__buttons {
  display: flex;
  justify-content: space-around;
}

.work__button {
  color: #d1d1d1;
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  transition: 0.2s ease-in-out;
}

.work__button:hover {
  color: var(--green);
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.2s ease-in-out;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

/*Active work*/

.active-work {
  background-color: #34ff7b3d;
  color: var(--green);
}

/* BREAKPOINTS */
/* large devices */
@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.25rem;
  }

  .work__card {
    padding: 1rem;
  }

  .work__img {
    margin-bottom: 0.7rem;
  }

  .work__title {
    margin-bottom: 0.25rem;
  }
}

/* medium devices */
@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .work__container {
    grid-template-columns: 1fr;
  }

  .work__img {
    width: 100%;
  }
}


/* small devices */
@media screen and (max-width: 350px) {
  .work__item {
    font-size: var(--small-font-size);
  }

  .work__filters {
    column-gap: 0.25rem;
  }
}