.services {
  background-color: #181818;
}

.services__container {
  grid-template-columns: repeat(3, 270px);
  justify-content: center;
  column-gap: 1.8rem;
}

.services__content {
  position: relative;
  border-radius: 40px;
  border: 2.6px solid #36c7692f;
  background-color: #151515;
  padding: 6rem 0 2rem 2.5rem;
}

.services__icon {
  display: block;
  font-size: 2rem;
  color: #d1d1d183;
  margin-bottom: var(--mb-1);
}

.services__title {
  font-size: var(--h3-font-size);
  color: #d1d1d1;
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}

.services__button {
  font-weight: bolder;
  color: #d1d1d1;
  font-size: var(--small-font-size);
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.services__button {
  transition: 0.2s;
  position: relative;
}

.services__button::after {
  content: " ";
  width: 34%;
  height: 2px;
  background-color: var(--green);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.2s ease-in-out;
}

.services__button:hover::after {
  width: 80%;
}

.services__button:hover {
  color: var(--green);
  transform: translateX(00.50rem);
}

.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: .2s;
}

.services__modal-content {
  width: 500px;
  position: relative;
  background-color: #151515;
  padding: 4.5rem 2.5rem 2.5rem;
  border-radius: 1.5rem;
}

.services__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: #d1d1d1;
  cursor: pointer;
}

.services__modal-title,
.services__modal-description {
  text-align: center;
}

.services__modal-title {
  color: #d1d1d1;
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

.services__modal-description {
  font-size: var(--small-font-size);
  padding: 0 3.5rem;
  margin-bottom: var(--mb-2);
}

.services__modal-services {
  row-gap: 0.75rem;
}

.services__modal-service {
  display: flex;
  align-items: center;
  column-gap: .5rem;
}

.services__modal-icon {
  color: var(--green);
}

.services__modal-info {
  color: #d1d1d1;
  font-size: var(--small-font-size);
}

.services__modal-close {
  color: var(--green);
  transition: 0.2s;
}

.services__modal-close:hover {
  color: #d1d1d184;
}

/* Active Modal */

.active-modal {
  opacity: 1;
  visibility: visible;
}

/* BREAKPOINTS */
/* large devices */
@media screen and (max-width: 992px) {
  .services__container {
    grid-template-columns: repeat(3, 218px);
  }
}

/* medium devices */
@media screen and (max-width: 768px) {
  .services__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .services__content {
    padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  }

  .services__icon {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  .services__modal-content {
    padding: 4.5rem 1.5rem 2.5rem;
  }

  .services__modal-description {
    padding: 0;
  }
}


/* small devices */
@media screen and (max-width: 350px) {
  .services__container {
    grid-template-columns: max-content;
  }

  .services__content {
    padding-right: 6rem;
  }
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  @keyframes fade-down {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@keyframes fade-right {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  @keyframes fade-right {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}