.scrollup {
  position: fixed;
  right: 2.5rem;
  bottom: -20%;
  background-color: var(--green);
  opacity: 0.8;
  padding: 0.3rem 0.5rem;
  border-radius: 20px;
  z-index: var(--z-tooltip);
  transition: 0.4s;
}

.scrollup__icon {
  font-size: 1.5rem;
  color: black;

}

.scrollup:hover {
  background-color: #36c76981;
}

/*Show Scroll*/

.show-scroll {
  bottom: 3rem;
}

/* BREAKPOINTS */
/* large devices */
@media screen and (max-width: 992px) {
  .scrollup {
    right: 1.5rem;
    padding: 0.25rem 0.4rem;
  }

  .scrollup__icon {
    font-size: 1.25rem;
  }
}

/* medium devices */
@media screen and (max-width: 768px) {
  .show-scroll {
    bottom: 4rem;
  }
}

/* small devices */
@media screen and (max-width: 350px) {
  .scrollup {
    right: 1rem;
  }
}