.skills__container {
  grid-template-columns: repeat(2, 350px);
  column-gap: 3rem;
  justify-content: center;
}

.skills__content {
  background-color: #181818;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  border-radius: 1.25rem;
}

.skills__title {
  color: #d1d1d1;
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  justify-content: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}

.skills__data {
  display: flex;
  column-gap: 0.3rem;
}

.bx-badge-check {
  color: var(--green);
}

.skills__ .bx-badge-check {
  font-size: 1rem;
  color: #d1d1d1;
}

.skills__name {
  color: #d1d1d1;
  font-size: var(--normal-font-size);
  font-weight: 500;
  line-height: 18px;
}

/* BREAKPOINTS */
/* large devices */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

/* medium devices */
@media screen and (max-width: 576px) {
  .skills_container {
    grid-template-columns: 1fr;
  }

  .skills_content {
    padding: 1.5rem;
  }
}


/* small devices */
@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  @keyframes fade-down {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@keyframes fade-right {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  @keyframes fade-right {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}