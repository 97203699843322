.footer {
  border-top: solid 1px var(--green);
  background-color: #131313;
}

.footer__container {
  padding: 2rem;
}

.footer__title,
.footer__link {
  color: #d1d1d1;
}

.footer__title {
  color: var(--green);
  text-align: center;
  margin-bottom: var(--mb-1);
}

.footer__link {
  color: #d1d1d138;
}

.footer__list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-2);
}

.footer__social {
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
}

.footer__social-link {
  color: #d1d1d188;
  padding: 0.4rem;
  font-size: 1.125rem;
  border-radius: 0.5rem;
  display: inline-flex;
  transition: 0.2s ease-in-out;
}

.footer__social-link:hover {
  color: #d1d1d1;
}

.footer__copy {
  display: block;
  margin-top: 2rem;
  color: #d1d1d1;
  text-align: center;
  font-size: var(--smaller-font-size);
}

.footer__copy a {
  color: #d1d1d1;
  text-decoration: none;
  transition: 0.2s ease-in-out;
}

.footer__copy a:hover {
  color: var(--green);
}

/* BREAKPOINTS */
/* large devices */
@media screen and (max-width: 992px) {
  .footer__social-link {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
}