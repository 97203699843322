.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: #151515;
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav__logo,
.nav__toggle {
  color: var(--green);
  font-weight: var(--font-medium);
}

.nav__logo:hover {
  filter: brightness(130%);
  transition: .2s;
}

.nav__list {
  display: flex;
  column-gap: 0.9rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: #d1d1d188;
  font-weight: var(--font-medium);
  transition: .2s;
}


.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

/* Active link */

.active-link {
  color: #d1d1d188;
}

.nav__link {
  position: relative;
}

.nav__link:hover {
  color: #D1D1D1;
  cursor: pointer;
}

.nav__link::after {
  content: " ";
  width: 0%;
  height: 1px;
  background-color: var(--green);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.2s ease-in-out;
}

.nav__link:hover::after {
  width: 100%;
}

/* BREAKPOINTS */
/* medium devices */
@media screen and (max-width: 768px) {
  .header {
    top: initial;
    bottom: 0;
  }

  .nav {
    height: var(--header-height);
  }

  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: #151515;
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: .2s;
  }

  /* Show Menu */
  .show-menu {
    bottom: 0;
    transition: 0.7s ease-in-out;
  }

  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .nav__icon {
    font-size: 1.4rem;
  }

  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--green);
  }

  .nav__close:hover {
    color: #36c7696b;
    transform: scale(1.1);
    transition: 0.4s ease;
  }

  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }
}


/* small devices */
@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav__list {
    column-gap: 0;
  }
}