.test {
  margin: 100px;
  border: 1px solid rgb(9, 113, 241);
  background-color: rgb(9, 113, 241, 0.2);
  width: 100px;
  height: 50px;
  padding: 10px;
  position: relative;
  text-align: center;
}

@keyframes enterKF {
  0% {
    transform: scale(0);
    opacity: 0.1;
  }

  100% {
    transform: scale(1);
    opacity: 0.3;
  }
}

@keyframes exitKF {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* touchRipp */
.touchRippleRoot {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

/* ripple */
.ripple {
  opacity: 0;
  position: absolute;
}

.rippleVisible {
  opacity: 0.3;
  transform: scale(1);
  animation: enterKF 0.5s ease-in-out;
}

/* ripple child */
.child {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: currentColor;
}

.childLeaving {
  opacity: 0;
  animation: exitKF 0.5s ease-in-out;
}