.home {
  height: 100vh;
}

.home__container {
  row-gap: 7rem;
}

.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: #d1d1d188;
}

.home__social-icon:hover {
  color: #D1D1D1;
  transition: 0.2s ease-in-out;
}

.home__title {
  color: var(--green);
  font-size: 39px;
  border-right: 4px solid var(--green);
  width: 16ch;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 1.5s steps(30), blinking 0.6s infinite step-end;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blinking {
  50% {
    border-color: transparent;
  }
}

.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  color: #d1d1d188;
  margin-bottom: 20px;
  padding-left: 14.5rem;
  font-weight: var(--font-semi-bold);
}

.home__subtitle::before {
  content: ' ';
  position: absolute;
  width: 200px;
  height: 1px;
  background-color: #d1d1d1;
  left: 0;
  top: 1rem;
}

.home__description {
  max-width: 400px;
  color: #D1D1D1;
  margin-bottom: var(--mb-2);
}

.home__hand {
  width: 38px;
  height: 38px;
  margin-left: 0.8rem;
}

.button {
  position: relative;
  background-color: transparent;
  border: 2px solid #d1d1d1;
  border-radius: 15px;
  height: 50px;
  transition: 0.2s ease-in-out;
}

.button:hover {
  background-color: #d1d1d1;
  color: #1a1a1a;
}

.uil-arrow-right {
  font-size: 18px;
  margin-left: 8px;
}

.home__img {
  background: url(../../assets/foto-3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  box-shadow: inset 0 0 0 9px rgba(29, 26, 26, 0.598);
  order: 1;
  justify-self: center;
  animation: profile__animate 7s ease-in-out infinite 1s;
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.home__scroll {
  display: flex;
  align-items: center;
  margin-left: 9.25rem;
}

.home__scroll:hover {
  cursor: pointer;
}

.home__scroll-buttons {
  margin-left: auto;
}

.button__lng {
  font-weight: var(--font-medium);
  background-color: transparent;
  color: #d1d1d183;
  margin-right: 40px;
  transition: 0.2s ease-in-out;
  border-bottom: 4px dotted;
}

.button__lng:hover {
  background-color: transparent;
  color: #d1d1d1a7;
}

.uil-language {
  margin-right: 5px;
}

.wheel {
  animation: scroll 2s ease infinite;
}

.wheel:hover {
  cursor: default;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(3.75rem);
  }
}

.home__scroll-name {
  color: #d1d1d188;
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
  position: relative;
}

.home__scroll-name::after {
  content: " ";
  width: 0%;
  height: 2px;
  background-color: #d1d1d188;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.6s ease-in-out;
}

.home__scroll-name:hover::after {
  width: 100%;
}


.home__scroll-arrow {
  font-size: 1.25rem;
  color: #d1d1d188;
  animation: scroll-arrow 2s ease infinite;
}

@keyframes scroll-arrow {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(4px);
  }
}

/* BREAKPOINTS */
/* large devices */
@media screen and (max-width: 992px) {
  .home__content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home__hand {
    width: 26px;
    height: 26px;
  }

  .home__subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home__description {
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home__img {
    width: 250px;
    height: 250px;
    box-shadow: inset 0 0 0 8px rgba(29, 26, 26, 0.598);
  }

  .home__scroll {
    margin-left: 7.5rem;
  }
}

/* medium devices */
@media screen and (max-width: 768px) {
  .home__content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
  }

  .home__title {
    font-size: 32px;
  }

  .home__img {
    order: initial;
    justify-self: initial;
  }

  .home__data {
    grid-column: 1/3;
  }

  .home__img {
    box-shadow: inset 0 0 0 6px rgba(29, 26, 26, 0.598);
    width: 200px;
    height: 200px;
  }

  .home__scroll {
    display: none;
  }
}

/* small devices */
@media screen and (max-width: 350px) {
  .home__img {
    width: 180px;
    height: 180px;
  }

  .home__hand {
    width: 22px;
    height: 22px;
  }
}